
import AuthButton from '@/components/AuthButton.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PostCard from '@/components/PostCard.vue';
import RouteNames from '@/router/names';
import { Post } from '@/types';
import { aggregatePostData } from '@/util.posts';
import { defineComponent } from 'vue';
import Binoculars from './Binoculars.vue';

export default defineComponent({
    components: {
        AuthButton,
        Binoculars,
        LoadingIndicator,
        PostCard,
    },

    i18n: {
        messages: {
            en: {
                seeMore: 'Wanna see more? Sign Up for ISeeChange!',
                signUp: 'Sign up',
            },

            es: {
                seeMore: '¿Quiero ver más? ¡Regístrese en ISeeChange!',
                signUp: 'Inscribirse',
            },
        },
    },

    data() {
        return {
            columnsCount: 0,
            cutoff: Infinity,
            fetching: false,
            posts: [] as Post[],
        };
    },

    computed: {
        RouteNames(): typeof RouteNames {
            return RouteNames;
        },
    },

    watch: {
        posts: 'redrawColumns',
    },

    mounted() {
        this.fetchPosts();
        addEventListener('resize', this.redrawColumns);
    },

    beforeDestroy() {
        removeEventListener('resize', this.redrawColumns);
    },

    methods: {
        async fetchPosts() {
            if (this.fetching) return;
            try {
                this.fetching = true;
                const response = await this.$store.getters.apiClient.get('/posts/recent-selection');
                if (response.status !== 200) {
                    throw new Error('Failed to fetch posts');
                }
                this.posts = await aggregatePostData(
                    this.$store,
                    response.data.posts,
                    response.data.photos,
                    response.data.investigations,
                    response.data.users,
                    response.data.comments,
                    response.data.weatherUnits
                );
            } catch (error) {
                console.error(error);
                this.$store.dispatch('alertUser', { type: 'error', message: 'Could not fetch posts' });
            } finally {
                this.fetching = false;
            }
        },

        async redrawColumns() {
            const MAX_COLUMNS = 50;

            if (!(this.$refs.columns instanceof HTMLElement) || !(this.$refs.measure instanceof HTMLElement)) return;

            const columnsCountIs = this.columnsCount;
            const columnsCountWillBe = Math.max(1, Math.min(MAX_COLUMNS, Math.floor(this.$refs.columns.clientWidth / this.$refs.measure.offsetWidth)));

            if (columnsCountWillBe !== columnsCountIs || !isFinite(this.cutoff)) {
                this.restorePosts();
                this.columnsCount = columnsCountWillBe;
                await this.$nextTick(); // Wait for the columns to be created/destroyed.
                this.distributePosts();
            }

            this.resetCutoff();
        },

        restorePosts() {
            if (!(this.$refs.postsHolder instanceof HTMLElement)) return;
            const postElements = Array.from(this.$el.querySelectorAll<HTMLElement>(`[data-index][data-id]`));
            postElements.sort((e1, e2) => parseFloat(e1.dataset.index ?? '') - parseFloat(e2.dataset.index ?? ''));
            this.$refs.postsHolder.append(...postElements);
        },

        distributePosts() {
            if (!(this.$refs.postsHolder instanceof HTMLElement) || !(this.$refs.columns instanceof HTMLElement)) return;

            const postElements = Array.from(this.$el.querySelectorAll<HTMLElement>(`[data-index][data-id]`));
            const columns = Array.from(this.$refs.columns.children);

            for (const postElement of postElements) {
                const [shortestColumn] = columns.sort((c1, c2) => c1.scrollHeight - c2.scrollHeight);
                shortestColumn?.append(postElement);
            }
        },

        resetCutoff() {
            if (!(this.$refs.columns instanceof HTMLElement)) return;
            const columns = Array.from(this.$refs.columns.children);
            const [shortestColumn] = columns.sort((c1, c2) => c1.scrollHeight - c2.scrollHeight);
            this.cutoff = shortestColumn.scrollHeight === 0 ? NaN : shortestColumn.scrollHeight;
        },
    },
});

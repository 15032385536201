
import { TRACKER_ORIGIN } from '@/config';
import { defineComponent } from 'vue';

export default defineComponent({
    metaInfo: {
        title: 'Create a Post',
    },

    props: {
        startingPath: { type: String, required: true },
    },

    computed: {
        trackerUrl() {
            const embedRootPath = this.$route.path.replace(this.$route.params.pathMatch, '*');
            const embeddedUrl = new URL(location.href);
            embeddedUrl.pathname = embedRootPath;

            const trackerUrl = new URL(this.startingPath, TRACKER_ORIGIN);
            trackerUrl.searchParams.set('embedded-href', embeddedUrl.href);
            return trackerUrl.href;
        },
    },
});
